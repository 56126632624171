import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import TagsBox from '../components/TagsBox';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import AuthorsListing from '../components/AuthorsListing';
import ImageAttribution from '../components/ImageAttribution';
import useSiteMetadata from '../components/SiteMetadata';
import SocialLinks from '../components/SocialLinks';
import '../styles/prismjs.css';

export const EventPostTemplate = ({
  content,
  contentComponent,
  summary,
  title,
  eventDate,
  tagsBox,
  seo,
  heroImage,
  featuredimage,
  location,
  speakersListing,
  pageLocation,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="main container-narrow event-post">
      <section className="hero">
        {heroImage ? (
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: heroImage.src,
                alt: heroImage.alt ? heroImage.alt : '',
              }}
            />
          </div>
        ) : (
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage.src,
                alt: featuredimage.alt ? featuredimage.alt : '',
              }}
            />
          </div>
        )}
        
      </section>
      <section>
        <div className="relative grid-container flex-start">
          <div className="col-md-10 off-md-1 col-xs-12">
            <div className="hero-text">
              <h2 className="data-wrapper">
                <span className="event-date">{eventDate}</span>
                <span className="location">{location && ` - ${location}`}</span>
              </h2>
              <h1 className="hero-text-title">{title}</h1> 
            </div>
            {seo || ''}
            <h2 className="summary">{summary}</h2>
            <PostContent content={content} className="post-content event" />
            <ImageAttribution hero={heroImage} featured={featuredimage} />
            {tagsBox || ''}
          </div>
          <div className="col-md-10 off-md-1 col-xs-12">
            <SocialLinks label="Share this event:" url={pageLocation} />
          </div>
        </div>
        {speakersListing || ''}
      </section>
    </div>
  );
};

EventPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  summary: PropTypes.string,
  title: PropTypes.string,
  eventDate: PropTypes.string,
  tagsBox: PropTypes.object,
  seo: PropTypes.object,
  heroImage: PropTypes.object,
  featuredimage: PropTypes.object,
  location: PropTypes.string,
  speakersListing: PropTypes.object,
};

const EventPost = ({ data }) => {
  const { event } = data;
  const { frontmatter } = event;
  const { edges: speakers } = data.speakers;
  const { title } = useSiteMetadata();

  const seoDescription =
    frontmatter.summary.length > 120
      ? `${frontmatter.summary.substr(0, 117)}...`
      : frontmatter.summary;

  const pageLocation = `${process.env.PAGE_DOMAIN}${event.fields.slug}`;
  const eventDate = frontmatter.dateRange
    ? frontmatter.dateRange
    : frontmatter.date;
  const eventImage =
    process.env.PAGE_DOMAIN +
    frontmatter.socialImage.src.childImageSharp.fluid.src;

  return (
    <Layout pageType="DetailsPage">
      <EventPostTemplate
        content={event.html}
        contentComponent={HTMLContent}
        summary={frontmatter.summary}
        eventDate={eventDate}
        tagsBox={<TagsBox title={'Tags'} tags={frontmatter.tags} />}
        speakersListing={
          <AuthorsListing
            names={frontmatter.speakers}
            full={speakers}
            title={`About the speaker${
              frontmatter.speakers && frontmatter.speakers.length > 1 ? 's' : ''
            }`}
          />
        }
        title={frontmatter.title}
        heroImage={frontmatter.heroImage}
        featuredimage={frontmatter.featuredimage}
        socialImage={frontmatter.socialImage}
        location={frontmatter.location}
        pageLocation={pageLocation}
        seo={
          <>
            <GatsbySeo
              title={frontmatter.title}
              titleTemplate={`%s | ${title}`}
              description={seoDescription}
              canonical={frontmatter.canonical || pageLocation}
              openGraph={{
                title: `${frontmatter.title}`,
                description: `${frontmatter.summary}`,
                images: [
                  {
                    url: eventImage,
                  },
                ],
                url: pageLocation,
              }}
            />
            <JsonLd
              // prettier-ignore
              json={{
                "@context": "https://schema.org",
                "@type": "Event",
                "name": frontmatter.title,
                "startDate": eventDate,
                "eventStatus": "https://schema.org/EventScheduled",
                "location": {
                  "@type": "Place",
                  "name": frontmatter.location
                },
                "image": [eventImage],
                "description": seoDescription,
                "performer": frontmatter.speakers.map(speakerName => ({
                  "@type": "Person",
                  "name": speakerName
                }))
              }}
            />
          </>
        }
      />
    </Layout>
  );
};

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default EventPost;

export const pageQuery = graphql`
  query EventPostByID($id: String!, $people: [String!]!) {
    event: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        summary
        tags
        dateRange
        location
        speakers
      }
      fields {
        slug
      }
      ...featuredImageWide
      ...heroImageWide
      ...socialImage
    }
    speakers: allMarkdownRemark(
      filter: { frontmatter: { name: { in: $people } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          ...AuthorBoxFragment
        }
      }
    }
  }
`;
